import React, { useEffect, createRef } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import lottie from "lottie-web"
import StylisedText from "../components/elements/stylised-text"
import ButtonLink from "../components/elements/button-link"

import animation from "../../static/lottie/404.json"
import backgroundImg from '@/images/error_page_bg.webp'

const isBrowser = typeof window !== "undefined"

const NotFoundPage = ({ data }) => {

  let currentUrl = isBrowser ? window.location.pathname : "/";

  let animationContainer = createRef()
  const {
    //strapiGlobal,
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
      },
    },
  } = data

  const global = data.strapiGlobal
  const navbar = data.strapiNavbar
  const footer = data.strapiFooter

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => anim.destroy() // optional clean up for unmounting
  }, [animationContainer])

  return (
    <>
      <Seo seo={{ title: "404: Not found" }} global={global} />
      <Layout
        navbar={navbar}
        footer={footer}
        pageContext={{
          locale: defaultLocale,
          locales,
          defaultLocale,
          slug: "404",
        }}
      >
        <div className="notfound" style={{ backgroundImage: `url(${backgroundImg})` }}>
          <div className="notfound__inner">
            <div
              className="notfound__inner__animation-container"
              ref={animationContainer}
            />
            <div className="notfound__inner__title">
              <StylisedText
                data={[
                  {
                    id: 68,
                    text: "Something ",
                    style: "bold",
                    colour: "green",
                    colourOverride: null,
                  },
                  {
                    id: 69,
                    text: "went ",
                    style: "bold",
                    colour: "white",
                    colourOverride: null,
                  },
                  {
                    id: 70,
                    text: "wrong.",
                    style: "bold",
                    colour: "orange",
                    colourOverride: null,
                  },
                ]}
                type="h5"
              />

              <span className="notfound__inner__text space">
                (You know, the Internet!?
                <span className="notfound__inner__text__emoji">😒</span>)
                <br></br>
                Maybe you could try reloading this page.
              </span>
            </div>

            <div className="notfound__inner__buttons">
              <div>
                <ButtonLink
                  button={{
                    newTab: "false",
                    text: " Take me Home Scottie! 👽  ",
                    type: "tertiary",
                    url: "/",
                    icon: "false"
                  }}
                  appearance="tertiary"
                  key="1"
                />
              </div>
              <div>
                <ButtonLink
                  button={{
                    newTab: "false",
                    text: " Reload this page 🎰  ",
                    type: "primary",
                    url: currentUrl,
                    icon: "false"
                  }}
                  appearance="primary"
                  key="2"
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
    strapiGlobal {
      ...GlobalData
    }
    strapiNavbar {
      ...NavbarData
    }
    strapiFooter {
      ...FooterData
    }
  }
`
